
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

import MineDialog from "./../../components/dialog/index.vue";
import CityPos from "./../../components/cityPos/index.vue";
import MineLogin from "./../../components/login/index.vue";
import MineForget from "./../../components/forget/index.vue";
import MineRegister from "./../../components/register/index.vue";
import headerView from "./layLocation/index.vue";
import CopyRight from "./../../components/copyRight/index.vue";
import footerView from "./../../components/footer/index.vue";
import PrivacyPolicy from "./../../components/privacyPolicy/index.vue";
import HeadmasterMailbox from "./../../components/headmasterMailbox/index.vue";

import RegistrationAgreement from "./../../components/registrationAgreement/index.vue";

@Component({
  name: "Layout",
  components: {
    MineDialog,
    footerView,
    CityPos,
    MineLogin,
    MineForget,
    MineRegister,
    headerView,
    CopyRight,
    RegistrationAgreement,
    PrivacyPolicy,
    HeadmasterMailbox,
  },
  computed: {
    ...mapGetters("common", [
      "goToTopFlag",
      "commonComponentName",
      "registrationAgreementFlag",
    ]),
    ...mapGetters("course", ["loadMoreFlag"]),
  },
  methods: {
    ...mapMutations("common", [
      "setGoToTopFlag",
      "setCommonDialogFlag",
      "setCommonComponentName",
    ]),
    ...mapMutations("course", ["setLoadMoreFlag"]),
  },
})
export default class Layout extends Vue {
  goToTopFlag!: boolean;
  setGoToTopFlag!: any;
  commonComponentName!: string;
  setCommonDialogFlag!: any;
  setCommonComponentName!: any;
  registrationAgreementFlag!: boolean;
  loadMoreFlag!: boolean;
  setLoadMoreFlag!: any;

  private dialogFlag = false;
  private componentName = "";
  private dialogTitle = "";
  private curRegistrationAgreementFlag = false;
  private dialogWidth = "520px";

  private timer: any = null;
  private notSelect = false;

  private copyFlag = true;
  private bgfffFlag = false;
  @Watch("goToTopFlag", { immediate: true, deep: true })
  goToTopFlagWatch(newVal: any): void {
    if (newVal) {
      this.handleScroll();
    }
  }
  @Watch("$route", { immediate: true, deep: true })
  routeWatch(newVal: any): void {
    const _this: any = this;
    if (
      newVal.path == "/aboutUs/index" ||
      newVal.path == "/about/index" ||
      newVal.path == "/group/index" ||
      newVal.path == "/dashboard/index" ||
      newVal.path == "/aboutUs/index/" ||
      newVal.path == "/dashboard/index/" ||
      newVal.path == "/infomation/index" ||
      newVal.path == "/infomation/news"
    ) {
      this.notSelect = true;
    } else {
      this.notSelect = false;
    }
    if (newVal.path == "/personal/index" || newVal.path == "/personal/index/") {
      this.copyFlag = false;
    } else {
      this.copyFlag = true;
    }
    if (
      newVal.path == "/nearbyCampus/index" ||
      newVal.path == "/personal/index" ||
      newVal.path == "/nearbyCampus/index/" ||
      newVal.path == "/personal/index/"
    ) {
      this.bgfffFlag = true;
    } else {
      this.bgfffFlag = false;
    }
    console.log(_this.$refs.scrollView);
    this.$nextTick(() => {
      _this.$refs.layContentMain.scrollTo(0, 0);
    });
  }
  @Watch("commonComponentName", { immediate: true, deep: true })
  commonComponentNameWatch(newVal: string): void {
    const _tempObj: any = {
      MineLogin: this.$t("common.nav.denglu"),
      MineForget: this.$t("common.nav.wangjimima"),
      MineRegister: this.$t("common.nav.zhuce"),
      CityPos: this.$t("common.nav.xuanzechengshi"),
      PrivacyPolicy: this.$t("common.nav.yinsizhengce"),
      // HeadmasterMailbox: this.$t('common.nav.xiaozhangxinxiang')
    };
    if (newVal == "PrivacyPolicy") {
      this.dialogWidth = "80%";
    } else if (newVal == "HeadmasterMailbox") {
      this.dialogWidth = "700px";
    } else {
      this.dialogWidth = "520px";
    }
    this.dialogTitle = _tempObj[newVal];
    this.componentName = newVal;
  }
  @Watch("registrationAgreementFlag", { immediate: true, deep: true })
  registrationAgreementFlagWatch(newVal: boolean): void {
    this.curRegistrationAgreementFlag = newVal;
  }
  private handleScroll(): void {
    let currentPosition = 0,
      speed = 5,
      _this: any = this;
    if (_this.$refs.layContentMain) {
      this.timer = setInterval(() => {
        currentPosition =
          _this.$refs.layContentMain.scrollTop ||
          _this.$refs.layContentMain.scrollTop;
        currentPosition -= speed;
        if (currentPosition != 0) {
          // _this.$refs.layContentMain.scrollTo(0, currentPosition)
        } else {
          _this.$refs.layContentMain.scrollTo(0, 0);
          this.timeToNull();
        }
      }, 1);
    }
    _this.$refs.layContentMain.onmousewheel = function () {
      _this.timeToNull();
    };
  }
  private timeToNull() {
    this.setGoToTopFlag(false);
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
  private closeEvent(): void {
    this.setCommonComponentName("");
  }
  beforeDestroy() {
    this.timeToNull();
  }

  private scrollEvent(e: any): void {
    const scrollTop = e.target.scrollTop, // 变量scrollTop是滚动条滚动时，距离顶部的距离
      windowHeight = e.target.clientHeight, // 变量windowHeight是可视区的高度
      scrollHeight = e.target.scrollHeight; // 变量scrollHeight是滚动条的总高度
    // 滚动条到底部的条件
    if (scrollTop + windowHeight + 50 > scrollHeight) {
      if (!this.loadMoreFlag) {
        this.setLoadMoreFlag(true);
      }
    }
  }
  mounted() {
    const _this: any = this,
      layContentMain: any = _this.$refs.layContentMain;
    layContentMain.addEventListener("scroll", this.scrollEvent);
  }
}
